import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link'; 

export class DenGoControllerInterface extends Component {
  static displayName = DenGoControllerInterface.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render() {
    return (
      <div><div class="mainbody" align="center">
        <img src="images/DenGoControllerInterface_Setup_4.png" border="0" style={{ verticalAlign: 'middle' }} />
      </div>

        <p />
        <div class="mainheader" align="center">
          <a href="download/Konkyu.Bvets.DenGoControllerInterface.zip" target="_blank">[Download] Konkyu.Bvets.DenGoControllerInterface.zip (20KB)</a> <br />
                Version: 1.1 (Build 6468.508) <br />
                Release Date: 16 Sep 2017
		    </div>

        <p />

        <div class="mainheader">
          <hr />
          <h3>Content</h3>
          <ul>
            <li><Link to="#license">License</Link></li>
            <li><Link to="#introduction">1. Introduction</Link></li>
            <li><Link to="#prerequisite">2. Prerequisite</Link></li>
            <li><Link to="#installation">3. Installation</Link></li>
            <li><Link to="#configuration">4. Configuration</Link>
              <ul>
                <li><Link to="#controllerBehaviour">Illustrative Example of "Controller Behaviour" Options</Link></li>
              </ul>
            </li>
            <li><Link to="#systemSupport">5. Bugs Reporting &amp; System Support</Link></li>
            <li><Link to="#versionHistory">6. Release Note / Version History</Link></li>
          </ul>
          <hr />
          <h3 id="license">License</h3>
          <p>Konkyu.Bvets.DenGoControllerInterface<br />Copyright © 2002-2021 </p>
          <p>Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:</p>
          <p>The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.</p>
          <p>THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.</p>
          <hr />
          <h3 id="introduction">1. Introduction</h3>
          <ul>
            <li>Konkyu.Bvets.DenGoControllerInterface (The interface plugin) is a controller interface designed for using Densha De Go! USB Controllers by Taito in BVE Trainsim 5.</li>
            <li>The interface plugin is compatible with the following devices for Windows:
                        <ul>
                <li><a href="http://www.unbalance.co.jp/dengo/dgoc" target="_blank">DGOC-44U - 電車でGO!コントローラ（USB版)</a> (Densha De Go! Controller USB [Two-Handle])</li>
                <li><a href="http://www.unbalance.co.jp/dengo/ohc" target="_blank">DGC-255 - 電車でGO！ワンハンドルコントローラ（USB版)</a> (Densha De Go! One-Handle Controller USB)</li>
              </ul>
            </li>
          </ul>
          <hr />
          <h3 id="prerequisite">2. Prerequisite</h3>
          <ul>
            <li>You must have the following applications installed before using the interface:
                        <ul>
                <li><a href="http://bvets.net" target="_blank">BVE Trainsim 5.6</a> or above</li>
                <li><a href="http://www.microsoft.com/en-us/download/details.aspx?id=22" target="_blank">Microsoft .NET Framework 3.5</a></li>
                <li><a href="http://www.microsoft.com/en-us/download/details.aspx?id=35" target="_blank">DirectX End-User Runtime</a></li>
              </ul>
            </li>
          </ul>
          <hr />
          <h3 id="installation">3. Installation</h3>
          <ol>
            <li>Unzip the package.</li>
            <li>Place "Konkyu.Bvets.DenGoControllerInterface.dll" file in BVE Trainsim "Input Devices" directory.<br /><img src="images/DenGoControllerInterface_Setup_1.png" /><br /></li>
            <li>Ensure you have the controller plugged in before launching BVE Trainsim main program</li>
            <li>In BVE Trainsim game main screen, right click and select "Preferences...".<br /><img src="images/DenGoControllerInterface_Setup_2.png" /><br /></li>
            <li>Go to "Input device" tab, check the checkbox of "Konkyu.Bvets.DenGoControllerInterface" interface.<br /><img src="images/DenGoControllerInterface_Setup_3.png" /></li>
          </ol>
          <hr />
          <h3 id="configuration">4. Configuration</h3>
          <ul>
            <li>To configure controller properties, select "Konkyu.Bvets.DenGoControllerInterface" interface and click "Properties..." button.<br /><img src="images/DenGoControllerInterface_Setup_3.png" /><br /></li>
            <li>You may configure Controller Behaviour and Button Assignments in properties dialog.<br /><img src="images/DenGoControllerInterface_Setup_4.png" /><br /></li>
            <li>Options available for "Controller Behaviour":<br />
              <ul>
                <li>Type A: Fixed Max / Min Notches &amp; Allocate when Exceed (Default)
                                <ul>
                    <li>The train's EB / max. / min. power / brake notch will be fixed at controller EB / B8 / B1 / P1 / P5 notch respectively.</li>
                    <li>If the train's no. of power / brake notches exceed the notches available in the controller (P5 / B8), system will allocate the BVE notches on pro-rata basis.</li>
                    <li>If the train's no. of power / brake notches beneath the notches available in the controller (P5 / B8), system will apply max power / service brake notches in BVE when the controller notch position is beyond the train notches, except EB.</li>
                  </ul><br />
                </li>
                <li>Type B: Fixed Max / Min Notches &amp; Always Allocate
                                <ul>
                    <li>The train's EB / max. / min. power / brake notch will be fixed at controller EB / B8 / B1 / P1 / P5 notch respectively.</li>
                    <li>If the train's no. of power / brake notches exceed the notches available in the controller (P5 / B8), system will allocate the BVE notches on pro-rata basis.</li>
                    <li>If the train's no. of power / brake notches beneath the notches available in the controller (P5 / B8), system will allocate the controller notches on pro-rata basis.</li>
                  </ul><br />
                </li>
                <li>Type C: Fixed Max Notches &amp; Allocate when Exceed
                                <ul>
                    <li>The train's EB / max. power / brake notch will be fixed at controller EB / B8 / P5 notch respectively.</li>
                    <li>If the train's no. of power / brake notches exceed the notches available in the controller (P5 / B8), system will allocate the BVE notches on pro-rata basis.</li>
                    <li>If the train's no. of power / brake notches beneath the notches available in the controller (P5 / B8), system will apply max power / service brake notches in BVE when the controller notch position is beyond the train notches, except EB.</li>
                  </ul><br />
                </li>
                <li>Type D: Fixed Max Notches &amp; Always Allocate
                                <ul>
                    <li>The train's EB / max. power / brake notch will be fixed at controller EB / B8 / P5 notch respectively.</li>
                    <li>If the train's no. of power / brake notches exceed the notches available in the controller (P5 / B8), system will allocate the BVE notches on pro-rata basis.</li>
                    <li>If the train's no. of power / brake notches beneath the notches available in the controller (P5 / B8), system will allocate the controller notches on pro-rata basis.</li>
                  </ul><br />
                </li>
                <li>Type E: No Notch Conversion
                                <ul>
                    <li>No notch conversion if the train's no. of power / brake notches does not match with the notches available in the controller (P5 / B8).</li>
                    <li>If the train's no. of power / brake notches beneath the notches available in the controller (P5 / B8), system will apply max power / service brake notches in BVE when the controller notch position is beyond the train notches.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <h4 id="controllerBehaviour">Illustrative Example of "Controller Behaviour" Options</h4>
          <table class="table table-sm" style={{ textAlign: 'center' }}>
            <colgroup>
              <col style={{ width: 72 + 'pt' }} />
              <col span="15" style={{ width: 35 + 'pt' }} />
            </colgroup>
            <thead class="thead-default" style={{ textAlign: 'center' }}>
              <tr>
                <th></th>
                <th colspan="3" style={{ verticalAlign: 'top' }}>Type A<br />
                  <span style={{ fontSize: 10 + 'pt' }}>Fixed Max / Min Notch<br />
                                Allocate Notch if BVE Notch &gt; Joystick Notch</span></th>
                <th colspan="3" style={{ verticalAlign: 'top' }}>Type B<br />
                  <span style={{ fontSize: 10 + 'pt' }}>Fixed Max / Min Notch<br />
                                Allocate Notch if BVE Notch &lt;&gt; Joystick Notch</span></th>
                <th colspan="3" style={{ verticalAlign: 'top' }}>Type C<br />
                  <span style={{ fontSize: 10 + 'pt' }}>Fixed Max Notch<br />
                                Allocate Notch if BVE Notch &gt; Joystick Notch</span></th>
                <th colspan="3" style={{ verticalAlign: 'top' }}>Type D<br />
                  <span style={{ fontSize: 10 + 'pt' }}>Fixed Max Notch<br />
                                Allocate Notch if BVE Notch &lt;&gt; Joystick Notch</span></th>
                <th colspan="3" style={{ verticalAlign: 'top' }}>Type E<br />
                  <span style={{ fontSize: 10 + 'pt' }}>No Conversion</span></th>
              </tr>
            </thead>
            <thead class="thead-inverse" style={{ textAlign: 'center' }}>
              <tr>
                <th colspan="16" style={{ textAlign: 'center' }}>BVE Train Notch Example (Exclude EB)</th>
              </tr>
            </thead>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>Brake Notch</td>
              <td>B7</td>
              <td>B8</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td>B7</td>
              <td>B8</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td>B7</td>
              <td>B8</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td>B7</td>
              <td>B8</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td>B7</td>
              <td>B8</td>
              <td>B16</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>Power Notch</td>
              <td>P4</td>
              <td>P5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td>P4</td>
              <td>P5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td>P4</td>
              <td>P5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td>P4</td>
              <td>P5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td>P4</td>
              <td>P5</td>
              <td>P10</td>
            </tr>
            <thead class="thead-inverse">
              <tr>
                <th style={{ textAlign: 'center' }}>Joystick Notch</th>
                <th colspan="15" style={{ textAlign: 'center' }}>BVE Input Notch</th>
              </tr>
            </thead>
            <tr>
              <td class="table-danger" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>EB</td>
              <td class="table-danger">EB</td>
              <td class="table-danger">EB</td>
              <td>B9</td>
            </tr>
            <tr>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B8</td>
              <td class="table-warning">B7</td>
              <td class="table-warning">B8</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td class="table-warning">B7</td>
              <td class="table-warning">B8</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td class="table-warning">B7</td>
              <td class="table-warning">B8</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td class="table-warning">B7</td>
              <td class="table-warning">B8</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B16</td>
              <td class="table-danger">EB</td>
              <td>B8</td>
              <td>B8</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B7</td>
              <td>B7</td>
              <td>B7</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B14</td>
              <td>B6</td>
              <td>B7</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B14</td>
              <td>B7</td>
              <td>B7</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B14</td>
              <td>B6</td>
              <td>B7</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B14</td>
              <td>B7</td>
              <td>B7</td>
              <td>B7</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B6</td>
              <td>B6</td>
              <td>B6</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B12</td>
              <td>B5</td>
              <td>B6</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B12</td>
              <td>B6</td>
              <td>B6</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B12</td>
              <td>B5</td>
              <td>B6</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B12</td>
              <td>B6</td>
              <td>B6</td>
              <td>B6</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B5</td>
              <td>B5</td>
              <td>B5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B10</td>
              <td>B4</td>
              <td>B5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B10</td>
              <td>B5</td>
              <td>B5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B10</td>
              <td>B4</td>
              <td>B5</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B10</td>
              <td>B5</td>
              <td>B5</td>
              <td>B5</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B4</td>
              <td>B4</td>
              <td>B4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B7</td>
              <td>B4</td>
              <td>B4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B7</td>
              <td>B4</td>
              <td>B4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B8</td>
              <td>B4</td>
              <td>B4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B8</td>
              <td>B4</td>
              <td>B4</td>
              <td>B4</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B3</td>
              <td>B3</td>
              <td>B3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B5</td>
              <td>B3</td>
              <td>B3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B5</td>
              <td>B3</td>
              <td>B3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B6</td>
              <td>B3</td>
              <td>B3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B6</td>
              <td>B3</td>
              <td>B3</td>
              <td>B3</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B2</td>
              <td>B2</td>
              <td>B2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B3</td>
              <td>B2</td>
              <td>B2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B3</td>
              <td>B2</td>
              <td>B2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B4</td>
              <td>B2</td>
              <td>B2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B4</td>
              <td>B2</td>
              <td>B2</td>
              <td>B2</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B1</td>
              <td class="table-warning">B1</td>
              <td class="table-warning">B1</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B1</td>
              <td class="table-warning">B1</td>
              <td class="table-warning">B1</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B1</td>
              <td>B1</td>
              <td>B1</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B2</td>
              <td>B1</td>
              <td>B1</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>B2</td>
              <td>B1</td>
              <td>B1</td>
              <td>B1</td>
            </tr>
            <tr>
              <td class="table-active" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>N</td>
              <td class="table-active">N</td>
              <td class="table-active">N</td>
              <td class="table-active" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>N</td>
              <td class="table-active">N</td>
              <td class="table-active">N</td>
              <td class="table-active" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>N</td>
              <td class="table-active">N</td>
              <td class="table-active">N</td>
              <td class="table-active" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>N</td>
              <td class="table-active">N</td>
              <td class="table-active">N</td>
              <td class="table-active" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>N</td>
              <td class="table-active">N</td>
              <td class="table-active">N</td>
              <td class="table-active">N</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P1</td>
              <td class="table-warning">P1</td>
              <td class="table-warning">P1</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P1</td>
              <td class="table-warning">P1</td>
              <td class="table-warning">P1</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P1</td>
              <td>P1</td>
              <td>P1</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P2</td>
              <td>P1</td>
              <td>P1</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P2</td>
              <td>P1</td>
              <td>P1</td>
              <td>P1</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P2</td>
              <td>P2</td>
              <td>P2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P3</td>
              <td>P2</td>
              <td>P2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P3</td>
              <td>P2</td>
              <td>P2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P4</td>
              <td>P2</td>
              <td>P2</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P4</td>
              <td>P2</td>
              <td>P2</td>
              <td>P2</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P3</td>
              <td>P3</td>
              <td>P3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P6</td>
              <td>P3</td>
              <td>P3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P6</td>
              <td>P3</td>
              <td>P3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P6</td>
              <td>P2</td>
              <td>P3</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P6</td>
              <td>P3</td>
              <td>P3</td>
              <td>P3</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P4</td>
              <td>P4</td>
              <td>P4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P8</td>
              <td>P3</td>
              <td>P4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P8</td>
              <td>P4</td>
              <td>P4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P8</td>
              <td>P3</td>
              <td>P4</td>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P8</td>
              <td>P4</td>
              <td>P4</td>
              <td>P4</td>
            </tr>
            <tr>
              <td style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P5</td>
              <td class="table-warning">P4</td>
              <td class="table-warning">P5</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td class="table-warning">P4</td>
              <td class="table-warning">P5</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td class="table-warning">P4</td>
              <td class="table-warning">P5</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td class="table-warning">P4</td>
              <td class="table-warning">P5</td>
              <td class="table-warning" style={{ borderRight: 1 + 'px', borderRightStyle: 'solid', borderRightColor: '#eceeef' }}>P10</td>
              <td>P4</td>
              <td>P5</td>
              <td>P5</td>
            </tr>
          </table>
          <hr />
          <h3 id="systemSupport">5. Bugs Reporting &amp; System Support</h3>
          <ul>
            <li>Bugs Reporting &amp; System Support email: support@konkyu.net</li>
          </ul>
          <hr />
          <h3 id="versionHistory">6. Release Note / Version History</h3>
          <ul>
            <li>16 Sep 2017 - Version 1.1
                        <ul>
                <li>Always detect if controller is connected when unconnected. No need to restart / reactivate interface when trying to reconnect controller in game.</li>
              </ul>
            </li>
            <li>29 Apr 2017 - Version 1.0
                        <ul>
                <li>Initial release</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
