import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { TimeCapsule } from './components/TimeCapsule';
import { SanYingControllerInterface } from './components/SanYingControllerInterface';
import { SanYingControllerStatusMonitor } from './components/SanYingControllerStatusMonitor';
import { DenGoControllerInterface } from './components/DenGoControllerInterface';
import { DenGoSanYingControllerInterface } from './components/DenGoSanYingControllerInterface';
import { JRETSDenGoControllerInterface } from './components/JRETSDenGoControllerInterface';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/TimeCapsule' component={TimeCapsule} />
        <Route path='/SanYingControllerInterface' component={SanYingControllerInterface} />
        <Route path='/SanYingControllerStatusMonitor' component={SanYingControllerStatusMonitor} />
        <Route path='/DenGoControllerInterface' component={DenGoControllerInterface} />
        <Route path='/DenGoSanYingControllerInterface' component={DenGoSanYingControllerInterface} />
        <Route path='/JRETSDenGoControllerInterface' component={JRETSDenGoControllerInterface} />
      </Layout>
    );
  }
}
