import React, { Component } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


const fadeImages = [
  'images/top_image_01.jpg',
  'images/top_image_02.jpg',
  'images/top_image_05.jpg',
  'images/top_image_06.jpg',
  'images/top_image_07.jpg',
  'images/top_image_08.jpg',
  'images/top_image_09.jpg',
  'images/top_image_10.jpg'
];

const fadeProperties = {
  arrows: false,
  indicators: true
};

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <p>
          <div className="slide-container">
            <Fade {...fadeProperties}>
              {fadeImages.map((image, i) => {
                return (
                  <div className="each-fade">
                    <div>
                      <img src={image} />
                    </div>
                  </div>
                )
              })}
            </Fade>
          </div>
        </p>

        <p class="text-center">
          <a href="./SanYingControllerInterface">Konkyu.Bvets.SanYingControllerInterface</a><br />
          <small>San Ying OHC-PC01 Controller Interface for BVE Trainsim 5</small>
        </p>
        <p class="text-center">
          <a href="./SanYingControllerStatusMonitor">Konkyu.Bvets.SanYingControllerMonitor</a><br />
          <small>San Ying OHC-PC01 Controller Status Monitor</small>
        </p>
        <p class="text-center">
          <a href="./DenGoControllerInterface">Konkyu.Bvets.DenGoControllerInterface</a><br />
          <small>Densha De Go! USB Controller Interface for BVE Trainsim 5</small>
        </p>
        <p class="text-center">
          <a href="./DenGoSanYingControllerInterface">Konkyu.DenGo.SanYingControllerInterface</a><br />
          <small>San Ying OHC-PC01 Controller Interface for Densha De Go! (Windows version) and JR East Train Simulator</small><br />
          <small><b><font color="red">** Update:</font> <a href="./DenGoSanYingControllerInterface#installation">Important Note of using controller in Steam</a> <font color="red">**</font></b></small>
        </p>
        <p class="text-center">
          <a href="./JRETSDenGoControllerInterface">Konkyu.JRETS.DenGoControllerInterface</a><br />
          <small>Densha De Go! USB Controller Interface for JR East Train Simulator</small><br />
          <small><b><font color="red">** Update:</font> <a href="./JRETSDenGoControllerInterface#installation">Important Note of using controller in Steam</a> <font color="red">**</font></b></small>
        </p>
        <p />
        <div align="center">
          <a href="./TimeCapsule" target="_self" title="Time Capsule">
            <img src="images/capsule_close.jpg" />
          </a>
        </div>

        <p />
        <div align="center">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/863AST0ns3s" frameborder="0" allowfullscreen></iframe>
        </div>

      </div>
    );
  }
}
