import React, { Component } from 'react';

export class TimeCapsule extends Component {
  static displayName = TimeCapsule.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render() {
    return (
      <div>
        <div class="text-center" style={{marginBottom: 50, marginTop: 50}}>
          <a href="./TimeCapsule" target="_self" title="Time Capsule">
            <img src="images/capsule_open.jpg" />
          </a>
        </div>

        <p class="text-center">
          <a href="download/wrbve_v200hq.zip" target="_blank">[2004.BVE2] KCR West Rail - Tuen Mun to Nam Cheong</a>
        </p>
        <p class="text-center">
          <a href="download/wrbve_v300_b10.zip" target="_blank">[2006.BVE4] KCR West Rail - Tsuen Wan West to Nam Cheong</a>
        </p>
        <p class="text-center">
          <a href="download/erbve_v310_b10.zip" target="_blank">[2007.BVE4] KCR East Rail - Sheung Shui to Lok Ma Chau</a>
        </p>
        <p class="text-center">
          Resolution Changer: <a href="download/resch4ver10016.zip" target="_blank">BVE4</a> / <a href="download/resch2ver1001.zip" target="_blank">BVE2</a>
        </p>

        <p class="text-center">

          <a href="download/ControllerMonitor.zip" target="_blank">Controller Monitor</a><br />
          <small>
            Input Command Monitor Program for: <br />
            <a href="http://www.youtube.com/watch?v=8r0dHafmxsw" target="_blank">Master Controller II (for Train Simulator)</a>;<br />
            <a href="http://www.unbalance.co.jp/dengo/dgoc/" target="_blank">Densha De GO! Controller (USB)</a>; and<br />
            <a href="http://www.unbalance.co.jp/dengo/ohc/" target="_blank">Densha De GO! One-handle Controller</a>.<br /> 
            These 3 controllers will be supported in our future products.
          </small>
        </p>


        <div class="text-center" style={{ marginBottom: 50, marginTop: 50 }}>
          <img src="images/wrbve_20130401_01.jpg" />
        </div>

      </div>
    );
  }
}
