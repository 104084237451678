import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenu />
        <Container>
          <p>
            <a href="./" target="_self" title="Home">
              <img src="images/konkyu_logo.gif" />
            </a>
          </p>
          {this.props.children}
        </Container>
        <div class="text-center">
          <small>Copyright © 2002-2023 KONKYU GROUP. All rights reserved.</small>
        </div>
      </div>
    );
  }
}
